export enum ImageConstants {
  goldenPurplleIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650626995_purplle-22x.png',
  somethingWentWrongIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/mobile/1469626185_icons-05.png',
  menuIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1509964368_menu.jpg',
  menuHumburgerIcon="https://media6.ppl-media.com/mediafiles/ecomm/landingpage/1675170792_humburger-menu-1.svg",
  hamburgerCartSmileyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1510741636_hamburger.jpg',
  userDefaultIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1493299109_unisex.png',
  defaultFemaleProfileIcon = 'https://media4.ppl-media.com/static/img/profile/profile-150x150.png',
  defaultMaleProfileIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/misc/1452767361_default-icons-men-150x150.jpg',
  defaultUserProfileIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217334_user.png',
  maleUserColorProfileIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484565819_male-color.jpg',
  maleUserGreyProfileIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484565811_male-grey.jpg',
  femaleUserColorProfileIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1491982561_female-color-1.png',
  femaleUserGreyProfileIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1491982572_female-grey-1.png',
  shareFeedbackIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217322_sharefeedback.png',
  feedbackBgIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1604670278_feedback_bg.png',
  feebackUnhappyGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337667_unhappy_n.png',
  feebackUnhappyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337667_unhappy_s.png',
  feedbackDisappointedIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337665_disappointed_s.png',
  feedbackDisappointedGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337665_disappointed_n.png',
  feedbackImproveGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337666_improve_n.png',
  feedbackImproveIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337666_improve_s.png',
  feebackHappyGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337666_happy_n.png',
  feebackHappyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337666_happy_s.png',
  feebackVeryHappyGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337667_very-happy_n.png',
  feebackVeryHappyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1558337665_very-happy_s.png',
  tellusWhatYouThinkIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/mobile/1472468511_tell-us-what.jpg',
  reviewsRatingsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1491314747_reviews-ratings.png',
  greatIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643801810_great-icon.png',

  deliveryFeedbackSadGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652270420_group-9134.svg',
  deliveryFeedbackSadIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652354648_group-9135.svg',
  deliveryFeedbackImproveGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652355198_group-9136.svg',
  deliveryFeedbackImproveIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652355463_group-9137.svg',
  deliveryFeedbackHappyGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652355203_group-9063.svg',
  deliveryFeedbackHappyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652355580_group-9138.svg',
  extremelyLikelyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1652339731_group-9044.svg',

  googleLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1542974745_google-logo.png',
  facebookLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1542974744_fb-logo.png',
  whatsappShareIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1588160742_watsapp-share.png',
  muteIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1586941035_mute.png',
  volumeIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1586941036_volume.png',
  showPassword = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1599056208_show.png',
  hidePassword = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1599056207_hide.png',

  doubleTapForwardGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590832416_forword.gif',
  doubleTapBackwardGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590833430_backward.gif',
  swipeUpNextGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590833444_swipe-up.gif',
  swipeDownNextGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590833434_swipe-down.gif',
  swipeLeftNextGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590833439_swipe-left.gif',
  tapHoldSimilarVideosGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590833453_tap-and-hold.gif',
  playVideoGreyIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484995841_v-play.png',
  playVideoWhiteIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484995850_v-play-h.png',
  playVideoWhiteGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1587026166_play.png',
  smallPlayVideoGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1533029455_play-icon.png',
  pinchToZoomIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1586342134_pinchtozoom.png',
  youtubePlayBlackIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/misc/1464170043_youtube-icon-black-1.png',
  youtubePlayRedIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/misc/1464169089_youtube-icon-1.png',

  purplleIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1543587824_logo.png',
  purplleDotComLogoSvg = 'https://media6.ppl-media.com/static/purplle/img/purplle-logo-1.svg',
  purplleFulfilledIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1472630541_purplle-fullfilled.png',
  purplleHeartSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1536305162_heart-filled-1.svg',
  offerIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643285769_offer-icon.png',
  groupCheckIconSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643351869_group-6788.svg',
  groupPercentageIconSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643353871_group-6803.svg',
  groupCheckIconSvgSmall = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643355817_group-6798.svg',
  irresistableOffersIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1491205694_irresistible-offer.png',
  deliveryAddressIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1590648857_address.png',
  mailEnvelopeIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1480335904_vv.png',
  defaultItemIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1522316396_default_product_image_100_x_100.png',
  defaultRHIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1522316396_default_product_image_50_x_50.png',
  defaultDotIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1522221181_dot2x1.png',
  topArrowIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/promo/1467114832_top-arrow.png',

  purplleEliteIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1573739057_elite-icon.png',
  eliteIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1552979107_elite-icon.png',
  lightEliteLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1533020644_pd-elite-logo.png',
  eliteCheckIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1530855501_elite-icon.svg',
  largeEliteCheckIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1532341225_elite-icon-1.svg',
  filterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1657790419_icon-close-small.svg',
  similarIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1596456279_similar.png',
  similarPinkIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1592902447_similar-pink.png',
  similarPurplleIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1640270462_similar_products_icon.svg',
  similarGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1592902445_similar-pd.png',
  crossButtonGreyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1657876269_closebutton.svg',
  multiVariantsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1527936940_multi-variant.png',
  genuineProductIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1576663533_100-genuen-product.png',
  easyReturnIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1576663532_easy-return1.png',
  giftBoxIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1657854865_group-10504.png',
  personalizedHairConsultationBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1609155581_01.png',
  personalizedSkinConsultationBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1609155580_02.png',

  expertsRecommendedIcon = 'https://media8.ppl-media.com/mediafiles/ecomm/misc/1491474869_exp-h.png',
  beautyAssistantHomeIcon = 'https://media8.ppl-media.com/mediafiles/ecomm/misc/1491474507_beauty-assistance-home.png',
  assistanceBWIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1487233556_assistant_icon.png',
  beautyAssistantHelpIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1502868683_help.png',
  beautyProfileIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217353_beauty-profile.png',
  beautyAssistantLightPinkIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247975_beauty-assistant.jpg',
  helpCenterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247997_help-center.jpg',

  foundationFinderIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1545829914_sddefault.jpg',
  shadowIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499259676_shadow.png',
  shopOnTheGoIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247958_shop-on-the-go.png',

  labelIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1586879110_lable.png',
  goodVibesPapayaFacewash = 'https://media6.ppl-media.com/tr:h-750,w-750,c-at_max/static/img/product/141347/good-vibes-papaya-brightening-face-wash_5_display_1579690423_d781191c.jpg',
  appHeroWOWBanner = 'https://media6.ppl-media.com/tr:w-720,ar-40909-25000,c-at_max/mediafiles/ecomm/misc/1586196376_app-hero-.jpg',
  skinCareBanner = 'https://media6.ppl-media.com/tr:w-240,ar-100-139,c-at_max/mediafiles/ecomm/home/1585833638_skin-care-copy.jpg',
  vitressSponsoredBanner = 'https://media6.ppl-media.com/tr:h-250,w-250,c-at_max,oi-AD_35x235_small_kJWM53nMn1.png,ofo-top_right/static/img/product/261147/vitress-hair-freshener-combo-floral-bloom-meadow-zest-100-ml-each_9_display_1638525624_26283f62.jpg',

  createStoryBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1511529492_create-story.jpg',
  chatBotBanner = 'https://media7.ppl-media.com/mediafiles/ecomm/misc/1491473679_bg1a.jpg',

  eliteLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1594961611_elite-logo.png',
  eliteTextLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1537874538_elite-logo.png',
  deliveryIcon ='https://media6.ppl-media.com/mediafiles/ecomm/promo/1672657775_svg.svg',

  eliteSparkleIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1562933945_top-bg.png',
  eliteHeartCrownIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1649065328_elite12x.png',
  horizontalEliteLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444864_elite-logo-horizontal.png',
  confettiIconLeftSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1648805966_confetti-1.svg',
  confettiIconRightSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1648805965_confetti.svg',
  tickIconSvg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1648811106_tick.svg',
  eliteSmallLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1562735727_elite-small-logo.png',
  eliteMembershipBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1605606070_banner.jpg',
  eliteSavingsFreebiesIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1568114177_elite-savings-and-freebies.jpg',
  eliteOffersIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129296_elite-offers2x.png',
  eliteOffersIcon1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1568114200_elite-offer.jpg',
  eliteExperienceIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1562735726_elite-experience.png',
  tryEliteLogo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1537798844_try-logo-3x.png',
  eliteReferEarnPg1Banner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1621241131_refer-_-earn-setup-page_01.jpg',
  eliteReferEarnPg2Banner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1621241134_refer-_-earn-setup-page_02.jpg',
  eliteReferEarnPg3Banner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1621241137_refer-_-earn-setup-page_03.jpg',
  eliteReferEarnPg4Banner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1621241140_refer-_-earn-setup-page_04.jpg',
  eliteReferEarnPg5Banner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1621241143_refer-_-earn-setup-page_05.jpg',
  eliteBenefits = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643624660_mempage-benefits-vertical-wo-cashback.png',
  eliteCalculator = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1594718239_elite-calculator1.jpg',
  eliteOffersBlackGoldIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1568114200_elite-offer.jpg',
  eliteBrandsTitle = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1568817348_elite-brand.png',

  shippedIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1565180218_shipped-.png',
  goldenAccentIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610527841_golden-accent-1.png',
  goldenAccentTriangleIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610527841_golden-accent-2.png',

  firstMilestoneIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610527843_milestone-1.png',
  secondMilestoneIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136441_milestone-2.png',
  thirdMilestoneIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136442_milestone-3.png',
  fourthMilestoneIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136442_milestone-4.png',
  fifthMilestoneIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136442_milestone-5.png',
  milestoneCheckPointsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136443_milestone-check-points.png',

  beautySpenderMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444868_title.png',
  spendMeterLinesIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1543928991_spend-meter-lines.png',
  spendMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444865_icon-1.png',
  secondSpendMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444865_icon-2.png',
  thirdSpendMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444865_icon-3.png',
  fourthSpendMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444866_icon-4.png',
  fifthSpendMeterIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1610444866_icon-5.png',

  starProgressIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1611136443_progress-icon.png',

  oldTryOnIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1539840105_try-25.png', //Need to confirm with Fahim
  rotateDeviceIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1535540246_rotate-mobile.png',
  titleLineIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1575714095_title-line.png',

  emptyBagIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/webengage/1456403495_empty-bag-25022016.png',
  emptyWishlistIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217299_empty-wishlist.png',
  wishlistIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217343_wishlist.png',
  emptyCouponIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1515224180_coupon-empty-stats.png',
  emptyCardIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1654069584_group-7849.svg',
  recommendedIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1607531877_recommended.png',
  recommendationsIcon = 'https://media5.ppl-media.com/mediafiles/ecomm/misc/1484217312_recommendations.png',
  couponIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1564998790_coupon.png',
  checkIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643368305_path-6418.svg',
  instantCheckoutIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650616443_instant-checkout.svg',
  fasterRefundsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650616443_layer_1.svg',
  consolidateMoneyIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650616443_layer_1_copy.svg',
  mobileDesktopGroupImage = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1635416055_group-712x.png',

  googlePlayStoreIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1635246010_google_playstore.png',
  androidPlayStoreIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247983_google-play.png', //desktop footer
  smallAppStoreIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247967_app-store.png', //desktop footer
  appStoreIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1635245995_appstore.png',
  googlePlayIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/mobile/1462258966_play-icon.png',
  appleIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/mobile/1462258974_ios-icon.png',
  playStoreRatingBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1625650114_rate_us_banner.png',
  appStoreRatingBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1625828167_app-store-rating.png',
  appDownloadBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1703149844_download-app.jpg',

  paymentOptionsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1499177379_tt.jpg',
  bhimIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1554994200_bhim01-copy-2.png',
  purplleCredit = 'https://media6.ppl-media.com/mediafiles/ecomm/landingpage/1680696494_wallet.png',
  greyLargeGenuineProductIcon="https://media6.ppl-media.com/mediafiles/ecomm/landingpage/1681215037_genuine-products-liner-1.png",
  upiIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1554994200_vpn.png',
  cardTypesIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1557729166_cards.png',
  securityShieldIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1554881899_securityshield.png',
  visaCardIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/misc/1441454717_visa-05092015.png',
  masterCardIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1657099947_mask-group-792x.png',
  amexCardIcon = 'https://media4.ppl-media.com/mediafiles/ecomm/misc/1441454717_amex-05092015.png',
  defaultCardIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1676753541_paycarddefault.svg',
  cardSwipeGIF = 'https://media6.ppl-media.com/mediafiles/ecomm/mobile/1518073477_card-swap.gif',
  codIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1565180220_cod.png',
  savingsIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1565180215_savings.png',
  securePaymentIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1499247950_secure-payment.jpg',

  madLibsImageUrl = 'https://media6.ppl-media.com/mediafiles/ecomm/home/1506339492_fib-1.jpg',

  lipstickSurveyBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1651685493_freebie2.png',
  lipstickSurveyConfettiBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1642688260_pngtreerealistic-confetti-vector-png-free_6956245.png',
  giftBoxBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1642688259_giftbox-1.png',
  maskGroupIcon = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1643106195_mask-group-86.png',
  lipstickSurveyCheckOffersBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1634037990_check-offers1.jpg',

  likeIconAnim0Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119361_video-commerce-product-list-like-00.svg',
  likeIconAnim1Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119361_video-commerce-product-list-like-01.svg',
  likeIconAnim2Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119362_video-commerce-product-list-like-02.svg',
  likeIconAnim3Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119362_video-commerce-product-list-like-03.svg',
  likeIconAnim4Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119359_video-commerce-product-list-like-04.svg',
  likeIconAnim5Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119360_video-commerce-product-list-like-05.svg',
  likeIconAnim6Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119360_video-commerce-product-list-like-06.svg',
  likeIconAnim7Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119360_video-commerce-product-list-like-07.svg',
  likeIconAnim8Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119360_video-commerce-product-list-like-08.svg',
  likeIconAnim9Svg = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1578119361_video-commerce-product-list-like-09.svg',

  freeShippingBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627101_ship2x.png',
  chooseBirthdayBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627105_group-82462x.png',
  freeGiftsExtraDiscountsBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627104_group-82452x.png',
  beautyConsultationsBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627103_group-82442x.png',
  beautyClassesBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627102_group-82422x.png',
  mileStoneRewardsBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627103_group-82432x.png',
  cashOnDeliveryBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627106_group-82472x.png',
  prioritySupportBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1650627107_group-82482x.png',

  eliteSavingReviewsBanner1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129032_cx-12x.png',
  eliteSavingReviewsBanner2 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129033_cx-22x.png',
  eliteSavingReviewsBanner3 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129034_cx-32x.png',

  eliteExperienceBanner1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078743_elite-experience-1.png',
  eliteExperienceBanner2 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078744_elite-experience-2.png',
  eliteExperienceBanner3 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078745_elite-experience-3.png',
  eliteExperienceBanner4 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078679_elite-experience-01.png',
  eliteExperienceBanner5 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078680_elite-experience-02.png',
  eliteExperienceBanner6 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078680_elite-experience-01-zai.png',

  eliteBrandsGoodVibes = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129150_gv2x.png',
  eliteBrandsMaybelline = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129151_gv-12x.png',
  eliteBrandsMamaearth = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129152_gv-22x.png',
  eliteBrandsWOW = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129153_gv-32x.png',
  eliteBrandsAlpsGoodness = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129153_gv-42x.png',
  eliteBrandsFaces = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129154_gv-52x.png',
  eliteBrandsPlum = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129155_gv-62x.png',
  eliteBrandsStayQuirky = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129156_gv-82x.png',
  eliteBrandsPurplle = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129156_gv-72x.png',
  eliteBrandsMcaffeine = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129157_gv-92x.png',
  eliteBrandsDermdoc = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129158_gv-102x.png',
  eliteBrandsTheFaceShop = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129158_gv-112x.png',
  eliteBrandsTheMomsCo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129159_gv-122x.png',
  eliteBrandsCarmesi = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129160_gv-132x.png',
  eliteBrandsKamaAyurveda = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129161_gv-142x.png',
  eliteBrandsInnisfree = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129161_gv-152x.png',
  eliteBrandsVayam = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129162_gv-162x.png',
  eliteBrandsAsfaHalal = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1651129163_gv-172x.png',

  beautyQuizBanner = 'https://media6.ppl-media.com/mediafiles/ecomm/misc/1645445587_group-7194.png',
  //cart
  smallCrossBtn= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1669808457_group-9951.svg',
   pinCodeTruck ="https://media6.ppl-media.com/mediafiles/ecomm/mobile/1675837592_icon_delivery.svg",

  closeBtn= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1669808457_group-9951.svg',
  forgotPasswordAmico='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669807461_forgot-password-amico.png',
  triangleCap='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669709269_group-10617.png',
  cartCouponIcon='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669713839_icon_coupon.svg',
  greyReturnProductIcon='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669723573_group-601.png',
  greyGenuineProductIcon= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1669723596_group-8229.png',
  cartReturns= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1669723596_group-8229.png',
  freeDelivery='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669723612_np_direct-payment_1877736_000000.png',
  freeCod='https://media6.ppl-media.com/mediafiles/ecomm/promo/1669723629_np_free-delivery_3581384_000000.png',

  eliteBrandsGoodVibes1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078783_good-vibes.png',
  eliteBrandsWOW1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983429_brand-circular-logos_02.png',
  eliteBrandsMamaearth1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983430_brand-circular-logos_03.png',
  eliteBrandsAlpsGoodness1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078786_alps-goodness.png',
  eliteBrandsPlum1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606911410_brand-circular-logos_06.png',
  elitebrandsNyBae1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078784_nybae.png',
  eliteBrandsMcaffeine1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983430_brand-circular-logos_08.png',
  eliteBrandsStayQuirky1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078785_sq.png',
  eliteBrandsTheFaceShop1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606911412_brand-circular-logos_10.png',
  eliteBrandsPurplle1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078784_purplle.png',
  eliteBrandsTheMomsCo1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983431_brand-circular-logos_12.png',
  eliteBrandsFaces1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983431_brand-circular-logos_13.png',
  eliteBrandsDermdoc1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983431_brand-circular-logos_14.png',
  eliteBrandsCarmesi1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1606983432_brand-circular-logos_15.png',
  eliteBrandsVayam1 = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1569078785_vayam.png',

  //my account
  myAccountOrders = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553721_group-8132.svg',
  myAccountcustomerSupport = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553721_group-8139.svg',
  myAccountCoupons = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553724_icon_coupon.svg',
  myAccountWishlist = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553722_group-8156.svg',
  myAccountReviews = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553722_group-8158.svg',
  myAccountBeautyProfile = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553722_group-8160.svg',
  myAccountPayment = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553723_group-8162.svg',
  myAccountAddress = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553723_group-8201.svg',
  myAccountPurplleCredit = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553720_group-8026.svg',
  myAccountAddBankDetails = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553720_group-8051.svg',
  myAccountChangePassword = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1653553723_group-8170.svg',
  beautyProfileOnBoard = 'https://media6.ppl-media.com/mediafiles/ecomm/landingpage/1663760426_curly-hair.mp4',

  //skin expert
  skinExpert = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1659544137_group-82072x.png',
  skinAnalyserVideo = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1659545929_skin-analyser-video-version-2-1.mp4',

  // price reveal filter
  crossButton = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1658055404_group-9082.svg',

  // cart or freebies banner
  quickSilver= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1683179885_purpllecreditqwiksilver.svg',
  availableBalanceWallet='https://media6.ppl-media.com/mediafiles/ecomm/promo/1650609598_group-7842.svg',
  offersIcon = "https://media6.ppl-media.com/mediafiles/ecomm/home/1673262716_offer-1.svg",
  eliteSvgIcon = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1686892583_elite.svg",
  successSvgIcon = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1686892583_success.svg",

  // shopping experience smiley
  unhappySmileyN = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924952_unhappy_n.png",
  unhappySmileyS = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924952_unhappy_s.png",
  disappointedSmileyN = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924950_disappointed_n.png",
  disappointedSmileyS = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924950_disappointed_s.png",
  improveSmileyN = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924951_improve_n.png",
  improveSmileyS = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924952_improve_s.png",
  happySmileyN = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924951_happy_n.png",
  happySmileyS = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924951_happy_s.png",
  veryHappySmileyN = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924952_very-happy_n.png",
  veryHappySmileyS = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1555924953_very-happy_s.png",
  availableOffersIcon='https://media6.ppl-media.com/mediafiles/ecomm/promo/1672663572_offer.svg',
  confetti="https://media6.ppl-media.com/mediafiles/ecomm/promo/1676281549_confitte.svg",
  emptyCart= 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1669805391_empty-pana-2.png',
  successButton = 'https://media6.ppl-media.com/mediafiles/ecomm/promo/1681887705_group-80322x.png',

  instantCheckout = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1575449178_01-instant-checkout.png",
  fasterRefund = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1715319474_02-faster-refund.png",
  consolidatedMoney = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1575449178_03-consolidated-money.png",

  purplleVideo = "https://player.vimeo.com/progressive_redirect/playback/897095146/rendition/720p/file.mp4?loc=external&signature=5cf8273eeb7f22232df8709ffa3554b346203d0703c3732541da162d44925089",

  videoTagSM = "https://media6.ppl-media.com/mediafiles/ecomm/brand/1704198642_video-tag-sm.jpg",
  videoTagMD = "https://media6.ppl-media.com/mediafiles/ecomm/brand/1704198641_video-tag-md.jpg",
  videoTagLG = "https://media6.ppl-media.com/mediafiles/ecomm/brand/1704198641_video-tag-lg.jpg",

  simpleEliteIcon = "https://media6.ppl-media.com/mediafiles/ecomm/brand/1704712733_elite-icon-1.png",
  simpleOfferIcon = "https://media6.ppl-media.com/mediafiles/ecomm/promo/1672663572_offer.svg",
}

